import { months } from 'constants/date';
import moment from 'moment';

import {
    SET_REPLACEMENT_DATA,
    SET_REPLACEMENT_FILTER_OPTIONS,
    SET_REPLACEMENT_FILTERS,
    SET_REPLACEMENT_DATA_LOADING,
    RESET_REPLACEMENT_FILTERS,
    SET_REPLACEMENT_PROPERTY_ID
} from '@/redux/mutations';

export const initialState = {
    property_id: null,
    data: {},
    loading: true,
    optionsFilters: {
        years: [moment().year()],
        months: months,
        typeOfLoss: ['Morte', 'Descarte voluntário', 'Descarte involuntário']
    },
    selectedFilters: {
        sectors: ['Todos'],
        years: [moment().year()],
        months: months,
        typeOfLoss: ['Morte', 'Descarte voluntário', 'Descarte involuntário']
    }
};

const indicatorsPanelReplacementReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_REPLACEMENT_PROPERTY_ID:
            return {
                ...state,
                property_id: actions.payload
            };
        case SET_REPLACEMENT_DATA:
            return {
                ...state,
                data: actions.payload
            };
        case SET_REPLACEMENT_DATA_LOADING:
            return {
                ...state,
                loading: actions.payload
            };
        case SET_REPLACEMENT_FILTER_OPTIONS:
            return {
                ...state,
                optionsFilters: actions.payload
            };
        case SET_REPLACEMENT_FILTERS:
            return {
                ...state,
                selectedFilters: actions.payload
            };
        case RESET_REPLACEMENT_FILTERS:
            return {
                ...state,
                selectedFilters: {
                    sectors: ['Todos'],
                    years: [moment().year()],
                    months: months,
                    typeOfLoss: ['Morte', 'Descarte voluntário', 'Descarte involuntário']
                }
            };
        default:
            return state;
    }
};

export default indicatorsPanelReplacementReducer;
